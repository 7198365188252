<div *ngIf="layout.loader"
     class="overlay">
  <img src="/assets/images/loading_animation_web.gif"
       class="spinner"
       alt="">
  <span class="effect"
        *ngIf="layout.countdown > 0">
    {{ 'label_unknown_error' | translate: { seconds: layout.countdown } }}
  </span>
  <span class="effect txt-center"
        *ngIf="layout.countdown <= 0" [innerText]="layout.message ? layout.message : ('label_loading' | translate)">
  </span>
</div>