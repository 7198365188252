<div class="mkg-frontpanel detail">
    <div class="photo-column">
      <mat-icon *ngIf="!(dataService.user$ | async).photo"
                id="photo"
                [inline]="true">account_circle</mat-icon>
      <img *ngIf="(dataService.user$ | async)?.photo"
           [src]="(dataService.user$ | async).photo" />
    </div>
    <div class="field-column detail">
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.PERSON.NAME' | translate }}:</strong> 
        {{ (dataService.user$ | async).name }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.PERSON.DOCUMENT' | translate }}: </strong>
        {{ (dataService.user$|async).document | document }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.PERSON.BIRTHDAY' | translate }}: </strong>
        {{ (dataService.user$ | async).birthday |  date }}
        <!-- {{ user?.birthday |  dateFormat: { language: Utilities.language, time: false } }} -->
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'LIST.LANGUAGE' | translate }}:</strong> 
        {{ (dataService.user$ | async).lang | language | translate }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'LIST.GENDER' | translate }}:</strong> 
        {{ (dataService.user$ | async).gender | gender | translate }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'LIST.MARITAL_STATUS' | translate }}: </strong>
        {{ (dataService.user$ | async).maritalStatus | maritalStatus | translate }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.PERSON.PHONE' | translate }}:</strong> 
        {{ (dataService.user$ | async).phone1 | phone }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.PERSON.PHONE_2' | translate }}:</strong> 
        {{ (dataService.user$ | async).phone2 | phone }}
      </div>
      <div class="col-12">
        <strong>{{ 'PROPERTY.PERSON.EMAIL' | translate }}: </strong> 
        {{ (dataService.user$ | async).email | email }}
      </div>
      <div class="col-12">
        <strong>{{ 'PROPERTY.USER.INTEGRATED_EMAIL' | translate }}: </strong> 
        {{ (dataService.user$ | async).emailIntegrated | email }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.ADDRESS.STREET' | translate }}:</strong>
         {{ (dataService.user$ | async).address?.street }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.ADDRESS.NUMBER' | translate }}:</strong> 
        {{ (dataService.user$ | async).address?.number }}
      </div>
      <div class="col-12">
        <strong>{{ 'PROPERTY.ADDRESS.COMPLEMENT' | translate }}: </strong>
        {{ (dataService.user$ | async).address?.complement }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.ADDRESS.NEIGHBORHOOD' | translate }}: </strong>
        {{ (dataService.user$ | async).address?.neighborhood }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'PROPERTY.ADDRESS.CEP' | translate }}:</strong> 
        {{ (dataService.user$ | async).address?.cep | cep }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'OBJECT.CITY' | translate }}:</strong> 
        {{ (dataService.user$ | async).address?.cityName }}
      </div>
      <div class="col-12 col-lg-6">
        <strong>{{ 'OBJECT.STATE' | translate }}:</strong> 
        {{ (dataService.user$ | async).address?.stateName }}
      </div>
    </div>
    <button mat-raised-button
            color="primary"
            class="edit-button"
            [disabled]="!(dataService.company$ | async)"
            [title]="(dataService.company$ | async) ? 'Editar' : 'Entre em uma empresa para poder editar usuários' "
            type="button"
            (click)="edit()">
       
      {{ 'ACTION.SHARED.EDIT' | translate }}
    </button>
  </div>