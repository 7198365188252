import { LocalStorageService } from "../service/local.storage.service";

export class MessageUtilities {

  constructor(/*private localStorageService: LocalStorageService*/){

  }
  static getReadMessages(company: string): string[] {
    // let localStorageService: LocalStorageService
    // return JSON.parse(localStorageService.get('readMessages') || '{}')[company] || [];
    // return JSON.parse(localStorage.readMessages || '{}')[company] || [];
    return JSON.parse(localStorage['readMessages'] || '{}')[company] || [];
  }

  static read(message: string, company: string) {
    // let localStorageService: LocalStorageService
    // const readMessages = JSON.parse(localStorageService.get('readMessages') || '{}');
    // const readMessages = JSON.parse(localStorage.readMessages || '{}');
    const readMessages = JSON.parse(localStorage['readMessages'] || '{}');
    if (!readMessages[company]) {
      readMessages[company] = [];
    }
    readMessages[company].push(message);
    // localStorageService.set('readMessages', JSON.stringify(readMessages))
    //localStorage.readMessages = JSON.stringify(readMessages);
    localStorage['readMessages'] = JSON.stringify(readMessages);
  }

}
