import { Company } from "../../../../interface/company";
import { SideMenu } from "../../../../interface/side-menu";
import { companyStates } from "../../../../shared/lists/company-states";
import { COMPANY_TAB } from "../../../../shared/lists/company-tabs";
import { roles } from "../../../../shared/lists/roles";


export const MAKENA_MENU: (companyId: Company) => SideMenu[] = (company) => {
  const menu: SideMenu[] = [
    {
      component: 'company',
      href: ['/company'],
      icon: 'domain',
    },
    {
      component: 'all',
      icon: 'assignment',
      href: ['company', company.id, 'os', 'all'],
      shadows: ['budget_detail', 'rejected_detail', 'opened_detail', 'closed_detail', 'ended_detail']
    },
    {
      component: 'clients',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_ind',
      href: ['company', company.id, 'clients'],
      shadows: ['client-detail']
    },
    {
      component: 'vehicles',
      blockedRoles: [roles.mechanic.id],
      icon: 'directions_car',
      href: ['company', company.id, 'vehicles'],
      shadows: ['vehicle-detail']
    },
    {
      component: 'parts',
      blockedRoles: [roles.manager.id, roles.consultant.id, roles.mechanic.id],
      icon: 'settings',
      href: ['company', company.id, 'parts'],
      shadows: ['part-detail']
    },
    {
      component: 'labors',
      blockedRoles: [roles.manager.id, roles.consultant.id, roles.mechanic.id],
      svgIcon: 'labor',
      href: ['company', company.id, 'labors'],
      shadows: ['labor-detail']
    },
    {
      component: 'users',
      blockedRoles: [roles.mechanic.id, roles.consultant.id, roles.manager.id],
      icon: 'people',// @todo badge for new users
      href: ['company', company.id, 'users'],
      shadows: ['user-detail']
    }
  ];

  const childs: SideMenu['childs'] = [{
    component: 'ro-types',
    href: ['company', company.id, 'records', 'ro-types']
  }];

  const isMakenaAdmin = company.currentState === companyStates.MAKENA_ADMIN.id;

  if (isMakenaAdmin || company.tab.includes(COMPANY_TAB.Pesquisa)) {
    childs.unshift({
      component: 'survey-list',
      href: ['company', company.id, 'records', 'survey-list'],
      shadows: ['survey-edit']
    });
  }
  if (isMakenaAdmin || company.tab.includes(COMPANY_TAB.Checklist)) {
    childs.unshift({
      component: 'checklist-models',
      href: ['company', company.id, 'records', 'checklist-model']
    });
  }
  if (isMakenaAdmin || company.tab.includes(COMPANY_TAB.Cardápio)) {
    childs.unshift({
      component: 'carte-list',
      href: ['company', company.id, 'records', 'carte-list'],
      shadows: ['carte-edit']
    }, {
      component: 'additional-item',
      href: ['company', company.id, 'records', 'additional-item']
    },
    {
      component: 'parcels',
      href: ['company', company.id, 'records', 'parcels']
    });
  }

  const record: SideMenu = {
    component: 'records',
    blockedRoles: [roles.mechanic.id, roles.consultant.id, roles.manager.id],
    icon: 'note_add',
    childs,
    shadows: ['import-data']
  }
  menu.push(record);

  return menu;
};
