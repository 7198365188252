import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {

  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe(() => this.translateLabels());
    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE');
    this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
    this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE');
    this.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE');
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (!length || !pageSize) {
        return this.translate.instant('PAGINATOR.ZERO_OF_LENGTH', { length: length });
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      const params = { start: startIndex + 1, end: endIndex, length: length };
      return this.translate.instant('PAGINATOR.START_END_OF_LENGTH', params);
    }
    this.changes.next();
  }

}
