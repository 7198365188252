import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChecklistModel, QUESTION_TYPE, Question } from '../../../interface/checklist-model';

/**
  * Optional injections can't be used with optional property operator (?)
  * 
  * do:
  * @Optional() @Inject(MAT_DIALOG_DATA) private data: any
  * 
  * 
  * don't:
  * @Inject(MAT_DIALOG_DATA) private data?: any
  * 
  **/
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AddChecklistQuestionComponent } from '../add-checklist-question/add-checklist-question.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-checklist-model',
  templateUrl: './add-checklist-model.component.html',
  styleUrls: ['./add-checklist-model.component.scss']
})
export class AddChecklistModelComponent implements OnInit {

  QUESTION_TYPE = QUESTION_TYPE;

  form: FormGroup = new FormBuilder().group({
    _id: "",
    group: "",
    // url: "",
    // defaultText: "",
    questions: [[]]
  });


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: ChecklistModel,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue(this.data)
    }
  }

  set questions(questions: Question[]) {
    this.form.get('questions').setValue(questions.slice());
  }

  get questions(): Question[] {
    return this.form.get('questions').value || [];
  }

  public addQuestion() {
    const questions = this.questions;
    this._dialog.open(AddChecklistQuestionComponent, {
      minWidth: '50vw'
    }).afterClosed()
      .pipe(first())
      .subscribe(question => {
        if (question) {
          questions.push(question);
          this.questions = questions;
        }
      });
  }

  public editQuestion(question: Question) {
    const index = this.questions.indexOf(question);
    this._dialog.open(AddChecklistQuestionComponent, {
      data: question,
      minWidth: '50vw'
    }).afterClosed()
      .pipe(first())
      .subscribe(updatedQuestion => {
        if (updatedQuestion) {
          this.questions[index] = updatedQuestion;
          this.questions = this.questions; // update table
        }
      });
  }

  removeQuestion(index: number) {
    const questions = this.questions;
    questions.splice(index, 1);
    this.questions = questions;
  }

}
