<div [ngClass]="['firebase-card', 'mat-elevation-z8', (layout.isHandset$ | async) ? 'mobile' : 'desktop']">

    @if(!(layout.isHandset$ | async)){
      @switch (APP_NAME) {
        @case ("MekanicWEB") {
          <div class="robot-column">
            <div class="speech-bubble">{{ 'INFO.SIGN_IN_OR_SIGN_UP' | translate }}</div>
            <img *ngIf="valid"
                class="info-image"
                src="/assets/images/robot/robot_default.svg" />
            <img *ngIf="!valid"
                class="info-image"
                src="/assets/images/robot/robot_creep.svg" />
          </div>
        }
        @case ("PO360") {
          <img src="assets/PO360/Logomarca/PNG/VERT. Escuro Principal.png"
                style="max-width: 50%;"
                alt="PO360">
        }
        @case ("Makeasy") {
          <img style="max-width: 50%;"
                src="/assets/images/makena_logos/MAKENA_360_bg-transparent.png"
                alt="Makeasy 360" />
        }
      }
    }

    <div>
      <h2>{{ 'ACTION.USER.SIGN_UP' | translate }}</h2>
      <form [formGroup]="form">
        <mat-form-field class="larger-field"
                        appearance="outline">
          <mat-label>{{ 'PROPERTY.PERSON.NAME' | translate }}</mat-label>
          <input (blur)="changeName()"
                 (change)="changeName()"
                 (input)="validate()"
                 matInput
                 required
                 type="text"
                 autocomplete="off"
                 formControlName="name" />
          <mat-icon matPrefix>person</mat-icon>
          <mat-error *ngIf="form.get('name').hasError('required')">
            {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="larger-field"
                        appearance="outline">
          <mat-label>{{ 'PROPERTY.PERSON.EMAIL' | translate }}</mat-label>
          <input (blur)="changeEmail()"
                 (change)="changeEmail()"
                 (input)="validate()"
                 matInput
                 required
                 type="email"
                 autocomplete="off"
                 formControlName="email" />
          <mat-icon matPrefix>mail</mat-icon>
          <mat-error *ngIf="form.get('email').hasError('required')">
            {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('email')">
            {{ 'VALIDATION.INVALID_VALUE' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="larger-field"
                        appearance="outline">
          <mat-label>{{ 'PROPERTY.USER.PASSWORD' | translate }}</mat-label>
          <input (blur)="changePassword()"
                 (change)="changePassword()"
                 (input)="validate()"
                 matInput
                 required
                 autocomplete="off"
                 minlength="6"
                 maxlength="50"
                 formControlName="password"
                 [type]="show ? 'text' : 'password'" />
          <mat-icon matPrefix>lock</mat-icon>
          <mat-icon matSuffix
                    (click)="show = !show">
            {{ show ? 'visibility' : 'visibility_off' }}
          </mat-icon>
          <mat-error *ngIf="form.get('password').hasError('required')">
            {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('password').hasError('minlength')">
            {{ 'error_password_length' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="button-group">
          <button aria-label="back"
                  mat-raised-button
                  color="accent"
                  type="button"
                  (click)="location.back()">
            <mat-icon class="button-icon">arrow_back</mat-icon>
            {{ 'label_back_button' | translate }}
          </button>
          <button aria-label="login"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (singleClick)="onSubmit()"
                  [disabled]="form.invalid">
            <mat-icon class="button-icon">subdirectory_arrow_right</mat-icon>
            {{ 'label_signup_btn' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>