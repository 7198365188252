import { Component } from '@angular/core';
import { LayoutService } from '../../../service/layout.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {

  interval;

  constructor(public layout: LayoutService) {
  }

  ngOnInit(): void {
    this.interval = setInterval(()=>{
      if (this.layout.countdown <= 0) {
        return
      }
      this.layout.countdown--
    }, 1000)
  }


  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

}
