import { Component, OnInit, OnDestroy } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { Router } from '@angular/router';
// import { LayoutService } from '../../../service/layout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
// import { SnackbarService } from '../../../service/snackbar.service';
// import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { DataService } from 'src/app/service/data.service';
// import { auth } from 'firebase/app';
// import { environment } from 'src/environments/environment';

//new
import {
  GoogleAuthProvider} from '@angular/fire/auth';
import { DataService } from '../../../service/data.service';
import { Router } from '@angular/router';
import { LayoutService } from '../../../service/layout.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SnackbarService } from '../../../service/snackbar.service';
import { TranslatePipe } from '@ngx-translate/core';



@Component({
  selector: 'app-firebase-sign-in',
  templateUrl: './firebase-sign-in.component.html',
  styleUrls: ['./firebase-sign-in.component.scss']
})
export class FirebaseSignInComponent implements OnInit, OnDestroy {

  form: FormGroup;
  show = false;
  userNotFound = false;
  wrongPassword = false;
  passwordTouched = false;
  emailTouched = false;
  isMakena = environment.makena;
  APP_NAME = environment.envName;

  private destroy = new Subject();

  constructor(
    public auth: AngularFireAuth,
    public layout: LayoutService,
    private dataService: DataService,
    private snackbar: SnackbarService,
    private router: Router,
    private formBuilder: FormBuilder,
    // private translate: TranslateService,
  ) {
    // const localLanguage= Utilities.getLanguage()
    // const selectedLanguage: string = localLanguage ? localLanguage : translate.getDefaultLang()
    //  this.setLanguage(selectedLanguage)
  }

  // private setLanguage(language: string) {
  //   // Specifies the current languages to the service
  //   this.translate.use(language);
  // }

  ngOnInit() {
    this.auth.authState.subscribe(user => {
      this.layout.loader = false;
      if(user){
        this.auth.setPersistence('local');
        this.router.navigate(['company']);
      }
    })
    this.buildForm();
  }


  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  // async onSubmit() {
  //   this.layout.loader = true;
  //   const email = this.form.value.email;
  //   const password = this.form.value.password;
  //   try {
  //     await this.auth.auth.setPersistence(auth.Auth.Persistence.LOCAL);
  //     await this.auth.auth.signInWithEmailAndPassword(email, password);
  //   } catch (error) {
  //     if (error.code == 'auth/user-not-found') {
  //       this.wrongPassword = false;
  //       this.userNotFound = true;
  //       this.form.get('email').setErrors({ notFound: true });
  //     } else if (error.code == 'auth/wrong-password') {
  //       this.userNotFound = false;
  //       this.wrongPassword = true;
  //       this.form.get('password').setErrors({ wrong: true });
  //     } else {
  //       this.userNotFound = this.wrongPassword = false;
  //       this.snackbar.show('FIREBASE.NO_CONNECTION', false);
  //     }
  //   } finally {
  //     this.layout.loader = false;
  //   }
  // }

  async onSubmit() {
    this.auth.signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
    .catch(err => this.errorHandler(err));
  }

  validate() {
    if (this.form.get('email').valid) {
      this.userNotFound = false;
    }
    if (this.form.get('email').touched && !this.form.get('email').valid) {
      this.userNotFound = true;
    }
    if (this.form.get('password').valid) {
      this.wrongPassword = false;
    }
    if (this.form.get('password').touched && !this.form.get('password').valid) {
      this.wrongPassword = true;
    }
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ 
      prompt: 'select_account' ,
    });
    this.auth.signInWithPopup(provider);
  }

  errorHandler(err: any) {
    this.showLoginFailReason(err.message)
  }

  showLoginFailReason(errorMessage: string) {
    const errorReason = errorMessage.match(/auth\/?[a-zA-Z-]+/)?.[0]
    switch(errorReason?.replace("auth/","").trim()) {
      case "wrong-password":
        this.wrongPassword = true;
        this.form.get('password').setErrors({ wrong: true })
      break;
      case "user-not-found":
        this.userNotFound = true;
        this.form.get('email').setErrors({ notFound: true })
      break;
      default:
        return;
    }
  }
}
