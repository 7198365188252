<h3 mat-dialog-title>Editar requisição</h3>
<p><strong>{{ method }}</strong>&nbsp;{{ url }}</p>
<textarea [(ngModel)]="rawData"
          mat-input
          cdkTextareaAutosize
          cdkAutosizeMinRows="10"
          cdkAutosizeMaxRows="15"
          class="w-100"></textarea>
<mat-dialog-actions>
    <button mat-raised-button
            color="primary"
            (click)="onContinue()">Continuar</button>
</mat-dialog-actions>