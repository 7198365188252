import { Location } from '@angular/common';
import { Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import laborIcon from '../../../../assets/images/icons/labor';
import whatsAppIcon from '../../../../assets/images/icons/whatsapp';
import { environment } from '../../../../environments/environment';
import { MessageUtilities } from '../../../class/message-utilities';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';
import { Utilities } from '../../../class/utilities';
import { Message } from '../../../interface/message';
import { SideMenu } from '../../../interface/side-menu';
import { CompanyService } from '../../../service/company.service';
import { DataCacheService } from '../../../service/data-cache.service';
import { DataService } from '../../../service/data.service';
import { LayoutService } from '../../../service/layout.service';
import { MessageService } from '../../../service/message.service';
import { ReverseRouterService } from '../../../service/reverse-router.service';
import { RoService } from '../../../service/ro.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { authorizationTypes } from '../../../shared/lists/authorization-types';
import { companyStates } from '../../../shared/lists/company-states';
import { messageTypes } from '../../../shared/lists/message-types';
import { RO_STATES } from '../../../shared/lists/ro-states';
import { roles } from '../../../shared/lists/roles';
import { LegacyService } from '../../page/company/legacy-os/legacy.service';
import { MAKENA_MENU } from './menu/makena-menu';
import { MEKANIC_MENU } from './menu/mekanic-menu';
import { TCAR_INTEGRATED_MENU } from './menu/tcar-integrated-menu';
import { Company } from '../../../interface/company';
import { MkgUser } from '../../../interface/user';
import { filter, first } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { fade4, fadeAnimation, fadeSlide, routerTransition, triggerAnimation } from './animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [fade4, fadeAnimation, routerTransition, triggerAnimation, fadeSlide]
})
@Injectable()
export class HeaderComponent extends UnsubscribeAll implements OnInit, OnDestroy {

  @ViewChild('rightDrawer', { static: false }) rightDrawer: MatSidenav;

  Utilities = Utilities;
  companyStates = companyStates;
  RO_STATES = RO_STATES;
  authorizationTypes = authorizationTypes;
  messageTypes = messageTypes;
  roles = roles;

  roStatesKeys: string[] = [];
  currentUser: any;
  messages: Message[];
  unread = 0;
  newUsers = 0;

  menu: SideMenu[] = [];
  isMakena = environment.makena;
  isPO360 = environment.dota;
  APP_NAME = environment.envName;
  isProduction = environment.production;

  constructor(
    public companyService: CompanyService,
    public dataService: DataService,
    public layout: LayoutService,
    private reverseRouter: ReverseRouterService,
    private location: Location,
    private messageService: MessageService,
    private roService: RoService,
    private auth: AngularFireAuth,

    private router: Router,
    private _activeRoute: ActivatedRoute,
    // private dateAdapter: DateAdapter<any>,
    private snackbar: SnackbarService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private _dataCacheService: DataCacheService,
    private _legacyService: LegacyService
  ) {
    super();
    this.iconRegistry.addSvgIconLiteral('labor', this.sanitizer.bypassSecurityTrustHtml(laborIcon));
    this.iconRegistry.addSvgIconLiteral('whatsapp', this.sanitizer.bypassSecurityTrustHtml(whatsAppIcon));

    // const localLanguage= Utilities.getLanguage()
    // const selectedLanguage: string = localLanguage ? localLanguage : translate.getDefaultLang()
    // this.setLanguage(selectedLanguage)
  }

  // private setLanguage(language: string) {
  //   // Specifies the available languages to the service
  //   // this.translate.addLangs(['en', 'pt', 'es'])
  //   // Specifies the current languages to the service
  //   this.translate.use(language);
  //   // // this.dateAdapter.setLocale(Utilities.getLanguageAndCountry());
  // }

  ngOnInit() {
    const sub = this.dataService.ready$.subscribe(([user, company]) => {
      if (company.currentState === companyStates.blocked.id) {
        return
      }
      this.setMenu(company, user);
      this.roStatesKeys = Object.keys(RO_STATES);
      this.getData();
    });
    this.subs.push(sub);
  }

  private setMenu(company: Company, user: MkgUser) {
    if (this.isMakena) {
      this.menu = MAKENA_MENU(company);
    } else {
      const isTCarIntegrated = company.currentState === companyStates.integrated.id;
      if (isTCarIntegrated) {
        this.menu = TCAR_INTEGRATED_MENU(company);
      } else {
        this.menu = MEKANIC_MENU(company, user);
        this._legacyService.getAll().then(legacies => {
          if (Array.isArray(legacies) && legacies.length) {
            const configMenu = this.menu.pop();
            this.menu.push({
              component: 'legacy',
              blockedRoles: [roles.mechanic.id],
              icon: 'join_left',
              href: ['company', company.id, 'legacy']
            }, configMenu);
          }

          if(company.discountControl !== 2) {
            let discountControlIndex = this.menu.findIndex(menu => menu.component === "discount-control");
            if(discountControlIndex) {
              this.menu.splice(discountControlIndex, 1);
            };
          };
        });
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ativaSnack() {
    this.newMessageSnackbar();
  }

  private getData() {
    this.newUsers = this.layout.menu.newUsers;
    this.messageService.getAll().subscribe(messagesMap => {
      try {
        if (this.messages && this.messages.length < messagesMap.size) {
          this.newMessageSnackbar()
        }
      } catch (error) {
        console.error(error)
      }

      this.messages = [];

      this.dataService.company$.pipe(
        filter(c => !!c),
        first()
      ).subscribe(company => {
        const readMessages = MessageUtilities.getReadMessages(company.id);
        this.unread = 0;
        if (!messagesMap) { return }
        messagesMap.forEach(message => {
  
          if (message.type >= messageTypes.authorized.id && message.type <= messageTypes.evaluated.id) {
            for (let readMessage of readMessages) {
              if (readMessage == message.id) {
                message.read = true;
                break;
              }
            }
            if (!message.read) {
              this.unread++;
            }
            this.messages.push(message);
          }
        });
        this.messages = this.messages.sort((a, b) => {
          if (a.read !== b.read) {
            return a.read ? 1 : -1;
          }
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  
        });
      })
    });

    this.dataService.user$.subscribe((user) => {
      this.companyService.getAll().then(companies => user.defineCompanies(companies))
    })

    if (this.layout.component == '') {
      this.newUsers = 0
      return
    }
    setTimeout(() => {
      if (this.layout.menu.newUsers > this.newUsers) {
        this.newUsers = this.layout.menu.newUsers;
        this.newRequestSnackbar()
      }
    }, 1600);
  }

  async toggleMessages() {
    this.rightDrawer.toggle();
    if (this.rightDrawer.opened && this.messages && this.messages.length && !this.messages[0].ro) {
      this.layout.loader = true;
      const actives = await this.roService.getAll(RO_STATES.all);
      const finisheds = await this.roService.getAll(RO_STATES.finished);
      const roList = new Map(Array.from(actives).concat(Array.from(finisheds)));

      this.messages.forEach(message => {
        message.ro = roList.get(message.text);
      });
      let messagesFiltered = [];

      for (const message of this.messages) {
        if (message.ro) {
          messagesFiltered.push(message);
        }
      }
      this.messages = messagesFiltered;
      this.layout.loader = false;
    }
  }

  async readAllMessages() {
    this.dataService.company$.pipe(
      filter(c => !!c),
      first()
    ).subscribe(company =>{
      for (const message of this.messages) {
        if (!message.read) {
          message.read = true;
          this.unread--;
          MessageUtilities.read(message.id, company.id);
        }
      }
    })
  }

  openMessage(message: Message) {
    this.dataService.company$.pipe(
      filter(c => !!c),
      first()
    ).subscribe(comp => {
      if (!message.read) {
        message.read = true;
        this.unread--;
        MessageUtilities.read(message.id, comp.id);
      }
      this.rightDrawer.toggle();
      window.location.pathname = `/company/${comp.id}/os/detail/${message.text}`;
    })
  }

  newMessageSnackbar() {
    try {
      this.snackbar.show('NEW', true);
    } catch (error) {
      this.layout.loader = false;
    }
  }

  newRequestSnackbar() {
    const user = this.layout.newUserName;
    try {
      this.snackbar.show('NEW_USER', true, { user: user });
    } catch (error) {
      this.layout.loader = false;
    }
  }

  goBack() {
    if (this.layout.component === 'blocked') {
      this.router.navigate(['/company'])
      return
    }
    if (window.history.length > 1) {
      this.location.back()
    } else {
      this.router.navigate(['/company'])
    }
  }

  async signOut() {
    this.auth.signOut();
  }

  public resetCache() {
    this.reverseRouter.clear();
    this._dataCacheService.reset();
  }

  public navigateOrExpand(menu: SideMenu) {
    if (!menu.href || this.layout.component === menu.component || menu.component === 'budgets') {
      this.layout.toggle(menu.component);
      return
    }
    this.router.navigate(menu.href);
  }

  changeCompany(company: Company){
    let currentUrl = this.router.url.split('/').slice(1);

    this.dataService.company$.pipe(first()).subscribe(currComp => {
      if(company && currComp){
        const idIndex = currentUrl.findIndex(id => id === currComp.id);
        if (idIndex !== -1) {
          currentUrl[idIndex] = company.id;

          if(currentUrl.find(urlPart => urlPart === "detail")) {
            currentUrl = currentUrl.splice(0, currentUrl.length - 2);
            currentUrl.push("budget");
          } else if(company.currentState === companyStates.integrated.id) {
            currentUrl = ["company", company.id, "os","budget"]
          };

          const newUrl = '/' + currentUrl.join('/');
          window.location.href = newUrl;
          return;
        } 
      }
      this.router.navigate(['company', company.id]);
    })

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
