import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExpenseType } from '../../../interface/expense-type';
import { ExpenseService } from '../../../service/expense.service';


@Component({
  selector: 'app-operation-without-expensetype',
  templateUrl: './operation-without-expensetype.component.html',
  styleUrls: ['./operation-without-expensetype.component.css']
})
export class OperationWithoutExpensetypeComponent implements OnInit {

  selectedExpense: string;
  expenseTypes: ExpenseType[];

  constructor(
    private expenseTypeService: ExpenseService,
    private dialogRef: MatDialogRef<OperationWithoutExpensetypeComponent>
  ) { }

  ngOnInit() {
    this.getExpenses()
  }

  private async getExpenses() {
    const expenses = await this.expenseTypeService.getAll()
    this.expenseTypes = expenses;
  }

  public async close(save: boolean){
    if(save){
      this.dialogRef.close(this.selectedExpense)
    } else {
      this.dialogRef.close()
    }
  }

}
