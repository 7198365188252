import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../service/data.service';
import { Auth } from '@angular/fire/auth';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { LayoutService } from '../../../service/layout.service';

import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';

@Component({
  selector: 'app-firebase-sign-up',
  templateUrl: './firebase-sign-up.component.html',
  styleUrl: './firebase-sign-up.component.scss'
})
export class FirebaseSignUpComponent extends UnsubscribeAll implements OnInit, OnDestroy {

  form: FormGroup;
  show = false;
  hide2 = true;
  passconfirm = false;
  confirm: string;
  userPassword: string;
  valid = true;
  nameTouched = false;
  emailTouched = false;
  passwordTouched = false;
  isMakena = environment.makena;
  APP_NAME = environment.envName;

  constructor(
    public location: Location,
    public layout: LayoutService,
    private dataService: DataService,
    private auth: AngularFireAuth,
    // private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
  ) {
    super()
    this.subs.push(this.auth.authState.subscribe(_user => {
      this.layout.loader = false;
    }));
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.unsubscribeAll()
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  confirmPassvalidate(pass, confirm): Boolean {
    return pass === confirm;
  }

  async onSubmit() {
    this.layout.loader = true;
    const email = this.form.value.email;
    const password = this.form.value.password;

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    this.layout.loader = false;
    // try {
    //   //await this.auth.auth.createUserWithEmailAndPassword(email, password);


    // } catch (error) {
    //   // this.snackbar.show('USER.NOT_ADDED', false);
    // } finally {
    //   // this.layout.loader = false;
    // }
  }

  changeName() {
    if (this.form.get('name').touched) {
      this.nameTouched = true;
    }
    this.validate();
  }

  changeEmail() {
    if (this.form.get('email').touched) {
      this.emailTouched = true;
    }
    this.validate();
  }

  changePassword() {
    if (this.form.get('password').touched) {
      this.passwordTouched = true;
    }
    this.validate();
  }

  validate() {
    if ((!this.nameTouched || this.form.get('name').valid)
      && (!this.emailTouched || this.form.get('email').valid)
      && (!this.passwordTouched || this.form.get('password').valid)) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }
}
