import { environment } from "src/environments/environment";
import { Company } from "../../../../interface/company";
import { SideMenu } from "../../../../interface/side-menu";
import { User } from "../../../../interface/user";
import { roles } from "../../../../shared/lists/roles";


export const MEKANIC_MENU: (company: Company, user: User) => SideMenu[] = (company, user) => {

  const APP_NAME = environment.envName;
  let vehicleIcon = "";
  
  if(APP_NAME == "MekanicAGRO"){
    vehicleIcon = "agriculture"
  } else {
    vehicleIcon = "directions_car";
  }

  const menus: SideMenu[] = [
    // company
    {
      component: 'company',
      href: ['/company'],
      icon: 'domain',
    },

    // quick access of favorite menus
    {
      component: 'favorites',
      icon: 'star',
      childs: [
        {
          component: 'configure',
          href: ['company', company.id, 'quick-access']
        }
      ]
    },

    // home ()
    {
      component: 'home',
      href: ['company', company.id, 'home'],
      blockedRoles: [],
      icon: 'home'
    },

    // budgets
    {
      component: 'budgets',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment',
      shadows: ['budget-create'],
      childs: [
        {
          component: 'budget',
          shadows: ['budget_detail', 'ro_create'],
          href: ['company', company.id, 'os', 'budget']
        },
        {
          component: 'rejected',
          shadows: ['rejected_detail'],
          href: ['company', company.id, 'os', 'rejected']
        }
      ]
    },

    // OSs
    {
      component: 'ro',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_turned_in',
      shadows: ['ro-create'],
      childs: [
        {
          component: 'opened',
          shadows: ['opened_detail'],
          href: ['company', company.id, 'os', 'opened']
        },
        {
          component: 'closed',
          shadows: ['closed_detail'],
          href: ['company', company.id, 'os', 'closed']
        },
        {
          component: 'ended',
          shadows: ['ended_detail'],
          href: ['company', company.id, 'os', 'ended']
        }
      ]
    },

    // calendar
    {
      component: 'calendar',
      icon: 'today',
      blockedRoles: [roles.mechanic.id],
      href: ['company', company.id, 'calendar']
    },

    // CRM
    {
      component: 'CRM',
      blockedRoles: [roles.mechanic.id],
      icon: 'reduce_capacity',
      childs: [
        {
          component: 'lead-list',
          href: ['company', company.id, 'crm', 'lead-list']
        },
        {
          component: 'contact-list',
          href: ['company', company.id, 'crm', 'contact-list']
        },
        {
          component: 'crm-events',
          href: ['company', company.id, 'crm', 'events']
        },
        {
          component: 'crm-dashboard',
          href: ['company', company.id, 'crm', 'dashboard']
        }
      ]
    },

    // clients
    {
      component: 'clients',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_ind',
      href: ['company', company.id, 'clients'],
      shadows: ['client-detail']
    },

    // vehicles
    {
      component: environment.agro ? 'machines' : 'vehicles',
      blockedRoles: [roles.mechanic.id],
      icon: vehicleIcon,
      href: ['company', company.id, 'vehicles'],
      shadows: ['vehicle-detail', 'search-vehicle']
    },

    // parts
    {
      component: 'parts',
      blockedRoles: [roles.mechanic.id],
      icon: 'settings',
      href: ['company', company.id, 'parts'],
      shadows: ['part-detail'],
      childs: [{
        component: 'maintenance',
        href: ['company', company.id, 'parts', 'maintenance']
      }]
    },

    // labors
    {
      component: 'labors',
      blockedRoles: [roles.mechanic.id],
      svgIcon: 'labor',
      href: ['company', company.id, 'labors'],
      shadows: ['labor-detail']
    },

    // cash (financial)
    {
      component: 'cash',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'local_atm',
      childs: [
        {
          component: 'cash-title',
          href: ['company', company.id, 'financial', 'cash-title'],
          shadows: ['cash-title-detail']
        },
        {
          component: 'receipt',
          href: ['company', company.id, 'financial', 'receipt'],
          shadows: ['closed-cashier']
        },
        {
          component: 'banks',
          href: ['company', company.id, 'financial', 'banks']
        },
        {
          component: 'conciliation',
          href: ['company', company.id, 'financial', 'conciliation'],
          shadows: ['conciliation-detail']
        },
        {
          component: 'detached',
          href: ['company', company.id, 'financial', 'detached']
        },
        {
          component: 'cash-flow-control',
          href: ['company', company.id, 'financial', 'cash-flow-control']
        }
      ]
    },

    // suppliers
    {
      component: 'suppliers',
      blockedRoles: [roles.mechanic.id],
      icon: 'local_shipping',
      href: ['company', company.id, 'suppliers'],
      shadows: ['supplier-detail']
    },

    // orders
    {
      component: 'orders',
      blockedRoles: [roles.mechanic.id],
      icon: 'ballot',
      href: ['company', company.id, 'order'],
      shadows: ['order-detail']
    },

    // users
    {
      component: 'users',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'people',
      href: ['company', company.id, 'users'],
      shadows: ['user-detail']
    },

    // invoices
    {
      component: 'invoices',
      blockedRoles: [roles.mechanic.id],
      icon: 'receipt',
      href: ['company', company.id, 'invoices'],
      shadows: ['invoice-detail'],
      childs: [
        {
          component: 'xml',
          href: ['company', company.id, 'invoices', 'xml']
        }
      ]
    },

    // operations
    {
      component: 'operations',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'category',
      href: ['company', company.id, 'operations'],
      shadows: ['operation-detail']
    },

    // stock
    {
      component: 'stock',
      blockedRoles: [roles.mechanic.id],
      icon: 'widgets',
      href: ['company', company.id, 'stock']
    },

    // indicators
    {
      component: "business_management",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'pie_chart',
      childs: [{
        component: 'indicators',
        href: ['company', company.id, 'dashboard'],
        shadows: [
          'info',
          'revenue-indicators-sales',
          'ranking-sellers-average-ticket',
          'ranking-sellers-clients-served',
          'ranking-sellers-sales',
          'revenue-indicators-average-ticket',
          'revenue-indicators-groups',
        ]
      }]
    },

    // Discount Control
    {
      component: 'discount-control',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'notifications_active',
      href: ['company', company.id, 'discount-control']
    },

    // reports
    {
      component: 'reports',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'description',
      childs: [
        {
          component: 'commissions',
          href: ['company', company.id, 'reports', 'commissions']
        },
        {
          component: 'dashboard',
          href: ['company', company.id, 'reports', 'dashboard']
        },
        {
          component: 'sales',
          href: ['company', company.id, 'reports', 'sales']
        },
        {
          component: 'user-sales',
          href: ['company', company.id, 'reports', 'user-sales']
        },
        {
          component: 'profits',
          href: ['company', company.id, 'reports', 'profits']
        },
        {
          component: 'profits-by-os',
          href: ['company', company.id, 'reports', 'profits-by-os']
        },
        {
          component: 'mechanic-time',
          href: ['company', company.id, 'reports', 'mechanic-time']
        },
        {
          component: 'dre',
          href: ['company', company.id, 'reports', 'dre']
        },
        {
          component: 'os-vehicle-history',
          href: ['company', company.id, 'reports', 'os-vehicle-history']
        },
        {
          component: 'vehicle-history',
          href: ['company', company.id, 'reports', 'vehicle-history']
        },
        {
          component: 'os-history',
          href: ['company', company.id, 'reports', 'os-history']
        }
      ]
    },

    // records
    {
      component: 'records',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'note_add',
      childs: [
        { component: 'application-list', href: ['company', company.id, 'records', 'applications'] },
        { component: 'bank-list', href: ['company', company.id, 'records', 'banks'] },
        { component: 'carte-list', href: ['company', company.id, 'records', 'carte-list'] },
        // { component: 'additional-item', href: ['company', company.id, 'records', 'additional-item'] },
        // { component: 'parcels', href: ['company', company.id, 'records', 'parcels'] },
        { component: 'checklist', href: ['company', company.id, 'records', 'checklist'] },
        { component: 'checklist-models', href: ['company', company.id, 'records', 'checklist-model'] },
        { component: 'payment-condition-list', href: ['company', company.id, 'records', 'payment-conditions'] },
        { component: 'custom-state-list', href: ['company', company.id, 'records', 'custom-states'] },
        { component: 'custom-state-budget-list', href: ['company', company.id, 'records', 'custom-states-budget'] },
        { component: 'cashier-event-list', href: ['company', company.id, 'records', 'cashier-events'] },
        { component: 'group-list', href: ['company', company.id, 'records', 'groups'] },
        { component: 'groups-by-expense-type-list', href: ['company', company.id, 'records', 'groups-by-expense-type'] },
        { component: 'brand-list', href: ['company', company.id, 'records', 'brands'] },
        { component: 'origin-list', href: ['company', company.id, 'records', 'origin-list'] },
        { component: 'tags', href: ['company', company.id, 'records', 'tags'] },
        { component: 'survey-list', href: ['company', company.id, 'records', 'survey-list'], shadows: ['survey-edit'] },
        { component: 'subgroup-list', href: ['company', company.id, 'records', 'subgroups'] },
        { component: 'subgroup-by-expense-type-list', href: ['company', company.id, 'records', 'subgroup-by-expense-type'] },
        { component: 'calendar-class', href: ['company', company.id, 'calendar', 'classlist'] },
        { component: 'expense-list', href: ['company', company.id, 'records', 'expense-types'] },
        { component: 'ro-types', href: ['company', company.id, 'records', 'ro-types'] },
        { component: 'labor-types-list', href: ['company', company.id, 'records', 'labor-types'] },
        { component: 'title-type-list', href: ['company', company.id, 'records', 'title-types'] },
        { component: 'control-messages', href: ['company', company.id, 'records', 'control-messages']}
      ]
    },

    // settings
    {
      component: 'settings',
      blockedRoles: [roles.mechanic.id],
      icon: 'build',
      href: ['company', company.id, 'settings']
    },

  ];


  if (Array.isArray(user.quickAccess) && user.quickAccess.length) {
    const favoriteIndex = menus.findIndex(m => m.component === 'favorites');
    const configureChild = menus[favoriteIndex].childs.pop();
    const quickChilds: SideMenu['childs'] = [];
    for (const quick of user.quickAccess) {
      let child: SideMenu['childs'][0];
      for (const menu of menus) {
        if (menu.component === quick) {
          child = {
            component: quick,
            href: menu.href
          }
          continue
        } else if (menu.childs) {
          for (const menuChild of menu.childs) {
            if (menuChild.component === quick) {
              child = menuChild;
              continue;
            }
          }
        }
      }

      if (child) {
        quickChilds.push(child);
      }
    }

    quickChilds.push(configureChild);

    menus[favoriteIndex].childs = quickChilds;
  }

  return menus
}
