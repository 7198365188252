<div [ngClass]="['firebase-card', 'mat-elevation-z8', (layout.isHandset$ | async) ? 'mobile' : 'desktop']">

  @if(!(layout.isHandset$ | async)){
    @switch (APP_NAME) {
      @case ("MekanicWEB") {
        <ng-container>
          <div *ngIf="!userNotFound && !wrongPassword"
               class="robot-column">
            <div class="speech-bubble">
              {{ 'INFO.SIGN_IN_OR_SIGN_UP' | translate }}
            </div>
            <img src="/assets/images/robot/robot_default.svg"
                 alt="robô mekanic" />
          </div>
          <div *ngIf="userNotFound && !wrongPassword"
               class="robot-column">
            <div class="speech-bubble login-error">
              {{ 'INFO.USER_NOT_FOUND' | translate }}
            </div>
            <img src="/assets/images/robot/robot_creep.svg"
                 alt="robô mekanic" />
          </div>
          <div *ngIf="wrongPassword"
               class="robot-column">
            <div class="speech-bubble login-error">
              {{ 'INFO.WRONG_PASSWORD' | translate }}
            </div>
            <img src="/assets/images/robot/robot_sad.svg"
                 alt="robô mekanic" />
          </div>
        </ng-container>
      }
      @case ("PO360") {
        <img src="assets/PO360/Logomarca/PNG/VERT. Escuro Principal.png"
             style="max-width: 50%;"
             alt="PO360">
      }
      @case ("Makeasy") {
        <img style="max-width: 50%;"
             src="/assets/images/makena_logos/MAKENA_360_bg-transparent.png"
             alt="Makeasy 360" />
      }
    }
  }

  <div>
    <h2>{{ 'TITLE.SIGN_IN' | translate }}</h2>
    <form [formGroup]="form">
      <mat-form-field class="login"
                      [class.login-error]="userNotFound"
                      appearance="outline">
        <mat-label>

          {{ 'PROPERTY.PERSON.EMAIL' | translate }}
        </mat-label>
        <input (blur)="validate()"
               (change)="validate()"
               (input)="validate()"
               tabindex="1"
               [class.login-error]="userNotFound"
               matInput
               required
               type="email"
               autocomplete="email"
               formControlName="email"
               (input)="form.get('password').updateValueAndValidity()" />
        <mat-icon matPrefix>email</mat-icon>
        <mat-error *ngIf="form.get('email').hasError('required')">
          <mat-icon>info</mat-icon>
          {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email')">
          <mat-icon>info</mat-icon>
          {{ 'VALIDATION.INVALID_EMAIL' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('notFound')">
          <mat-icon>info</mat-icon>
          {{ 'VALIDATION.EMAIL_NOT_FOUND' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-hint [ngClass]="
            {'hintWithoutErrors': !userNotFound,
             'hintWithErrors'   : userNotFound  }">
        {{ 'HINT.NO_ACCOUNT' | translate }}
        <a [routerLink]="['/sign-up']">
          {{ 'HINT.SIGN_UP_HERE' | translate }}
        </a>
      </mat-hint>
      <mat-form-field class="login"
                      [class.invalid]="wrongPassword"
                      appearance="outline">
        <mat-label>
          {{ 'PROPERTY.USER.PASSWORD' | translate }}
        </mat-label>
        <input (blur)="validate()"
               (change)="validate()"
               (input)="validate()"
               tabindex="2"
               [class.login-error]="wrongPassword"
               matInput
               required
               autocomplete="new-password"
               minlength="4"
               maxlength="50"
               formControlName="password"
               [type]="show ? 'text' : 'password'" />
        <mat-icon matPrefix>lock</mat-icon>
        <mat-icon matSuffix
                  id="visibility"
                  (click)="show = !show">
          {{ show ? 'visibility' : 'visibility_off' }}
        </mat-icon>
        <mat-error *ngIf="form.get('password').hasError('required')">
          <mat-icon>info</mat-icon>
          {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('minlength')">
          <mat-icon>info</mat-icon>
          {{ 'error_password_length' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('wrong')">
          <mat-icon>info</mat-icon>
          {{ 'VALIDATION.WRONG_PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-hint [ngClass]="
            {'hintWithoutErrors': !wrongPassword,
             'hintWithErrors'   :  wrongPassword  }">
        {{ 'HINT.FORGOTTEN_PASSWORD' | translate }}
        <a [routerLink]="['/reset-password']">
          {{ 'HINT.RESET_PASSWORD' | translate }}
        </a>
      </mat-hint>
      <div class="button-group">
        <button aria-label="google"
                mat-raised-button
                color="accent"
                type="button"
                tabindex="4"
                (click)="signInWithGoogle()">
          <img src="/assets/images/icons/google.svg">
          {{ 'ACTION.FIREBASE.GOOGLE' | translate }}
        </button>
        <button class="button-icon"
                aria-label="login"
                mat-raised-button
                color="primary"
                type="button"
                (click)="onSubmit()"
                tabindex="3"
                [disabled]="form.invalid">
          <mat-icon class="button-icon">subdirectory_arrow_right</mat-icon>

          {{ 'ACTION.FIREBASE.SIGN_IN' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<small *ngIf="APP_NAME === 'MekanicWEB'"
       [ngClass]="['terms', (layout.isHandset$ | async) ? 'mobile' : 'desktop']">
  <a href="https://www.mekanicgo.com.br/#terms"
     target="_blank"> Termos de uso e política de privacidade </a>
</small>