import { Component, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { Router } from '@angular/router';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';

@Component({
  selector: 'app-firebase-reset-password',
  templateUrl: './firebase-reset-password.component.html',
  styleUrl: './firebase-reset-password.component.scss'
})
export class FirebaseResetPasswordComponent extends UnsubscribeAll implements OnDestroy {
  email = new FormControl('', {
    validators: [Validators.required, Validators.email],
    updateOn: "change"
  });
  isMakena = environment.makena;
  APP_NAME = environment.envName;

  constructor(
    public layout: LayoutService,
    private auth: AngularFireAuth,
    private snackbar: SnackbarService,
    private router: Router
  ) {
    super();
    this.subs.push(this.auth.authState.subscribe(user => {
      if (user) {
        this.router.navigate(['company']);
      } else {
        this.layout.loader = false;
      }
    }));
  }

  override ngOnDestroy(): void {
    this.unsubscribeAll();
  }


  onSubmit() {
    this.layout.loader = true;
    this.auth
      .sendPasswordResetEmail(this.email.value)
      .then(() => this.snackbar.success('FIREBASE.RECOVERY_SENT'))
      .catch((err) => this.snackbar.error('FIREBASE.NOT_REGISTERED', undefined, err))
      .finally(() => this.layout.loader = false)
  }

  goBack() {
    if (window.history.length > 1) {
      try {
        history.back();
      } catch (error) {
        (location as any).back()
      }
    } else {
      this.router.navigate(['/'])
    }
  }

}
