import { Company } from "../../../../interface/company";
import { SideMenu } from "../../../../interface/side-menu";
import { roles } from "../../../../shared/lists/roles";

export const TCAR_INTEGRATED_MENU: (company: Company) => SideMenu[] = (company) => {

  const menus = [
    // company
    {
      component: 'company',
      href: ['/company'],
      icon: 'domain',
    },

    // budgets
    {
      component: 'budgets',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment',
      shadows: ['budget-create'],
      childs: [
        {
          component: 'budget',
          shadows: ['budget_detail', 'ro_create'],
          href: ['company', company.id, 'os', 'budget']
        },
        {
          component: 'rejected',
          shadows: ['rejected_detail'],
          href: ['company', company.id, 'os', 'rejected']
        }
      ]
    },

    // OSs
    {
      component: 'ro',
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_turned_in',
      shadows: ['ro-create'],
      childs: [
        {
          component: 'integrated_opened',
          shadows: ['opened_detail'],
          href: ['company', company.id, 'os', 'integrated_opened']
        },
        {
          // finalizadas
          component: 'finished',
          shadows: ['ended_detail'],
          href: ['company', company.id, 'os', 'ended']
        },

      ]
    },

    // users
    {
      component: 'users',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'people',
      href: ['company', company.id, 'users'],
      shadows: ['user-detail']
    },

    // records
    {
      component: 'records',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'note_add',
      childs: [
        { component: 'checklist', href: ['company', company.id, 'records', 'checklist'] },
        { component: 'checklist-models', href: ['company', company.id, 'records', 'checklist-model'] },
      ]
    }
  ];
  return menus
}
