import { HttpRequest } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pre-editor',
  templateUrl: './pre-editor.component.html',
  styleUrls: ['./pre-editor.component.scss']
})
export class PreEditorComponent {

  method: string;
  url: string;
  rawData: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: HttpRequest<any>,
    private _dialogRef: MatDialogRef<PreEditorComponent>
  ) {
    this.method = data.method;
    this.url = data.url;
    if (data && data.body) {
      try {
        const str = JSON.stringify(data.body || {}, undefined, 4);
        this.rawData = str;
      } catch (error) {
        this._dialogRef.close(data.body);
      }
    }
  }

  public onContinue() {
    try {
      const newBody = JSON.parse(this.rawData);
      this._dialogRef.close(newBody);
    } catch (error) {
      this._dialogRef.close(this.data.body || {});
    }
  }

}
