<h1 mat-dialog-title>{{ 'ACTION.RO.SCHEDULE' | translate }}</h1>
<form [formGroup]="form">
  <mat-dialog-content>
    <mat-form-field class="col-12">
      <input matInput
             required
             placeholder="{{ 'PROPERTY.RO.DATE' | translate }}"
             type="text"
             autocomplete="off"
             maxlength="10"
             formControlName="appointment"
             [matDatepicker]="datepicker"
             [min]="now"
             (dateInput)="form.get('time').updateValueAndValidity()" />
      <mat-datepicker-toggle matSuffix
                             [for]='datepicker'
                             [tabIndex]="-1"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
      <mat-error *ngIf="form.get('appointment').hasError('required')
          && !form.get('appointment').hasError('matDatepickerParse')">
        {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('appointment').hasError('matDatepickerParse')">
        {{ 'VALIDATION.INVALID_VALUE' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('appointment').hasError('matDatepickerMin')">
        {{ 'VALIDATION.PAST_DATE' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-12">
      <input matInput
             required
             readonly
             placeholder="{{ 'PROPERTY.RO.TIME' | translate }}"
             formControlName="time"
             [ngxTimepicker]="timepicker"
             [format]="24" />
      <ngx-material-timepicker #timepicker
                               [cancelBtnTmpl]="cancel"
                               [confirmBtnTmpl]="confirm"
                               (timeSet)="form.get('appointment').updateValueAndValidity()">
      </ngx-material-timepicker>
      <mat-error *ngIf="form.get('time').hasError('past')">
        {{ 'VALIDATION.PAST_TIME' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button aria-label="cancel"
            mat-raised-button
            color="accent"
            type="button"
            (click)="dialogRef.close()">
      {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button aria-label="save"
            mat-raised-button
            color="primary"
            type="button"
            (singleClick)="onSubmit()"
            [disabled]="form.invalid || !changed">
      {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<ng-template #cancel>
  <button aria-label="cancel"
          mat-raised-button
          color="accent"
          class="timepicker-button"
          type="button">
    {{ 'ACTION.SHARED.CANCEL' | translate }}
  </button>
</ng-template>
<ng-template #confirm>
  <button aria-label="save"
          mat-raised-button
          color="primary"
          class="timepicker-button"
          type="button">
    {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
  </button>
</ng-template>