<h1 matDialogTitle>{{ form.value._id ? 'Atualizar' : 'Criar'}} modelo de checklist</h1>
<mat-dialog-content>
    <form [formGroup]="form">
        <!-- group -->
        <mat-form-field class="col-12">
            <mat-label>Grupo</mat-label>
            <input matInput
                   formControlName="group">
        </mat-form-field>

        <!-- defaultText -->
        <!-- <mat-form-field class="col-12">
            <mat-label>Texto padrão</mat-label>
            <input matInput
                   formControlName="defaultText">
        </mat-form-field> -->

        <!-- url -->
        <!-- <mat-form-field class="col-12">
            <mat-label>URL da imagem de fundo</mat-label>
            <input matInput
                   formControlName="url">
        </mat-form-field> -->

        <button mat-button
                color="primary"
                class="txt-center"
                (click)="addQuestion()">Adicionar lista</button>

        <table mat-table
               [dataSource]="questions">
            <ng-container matColumnDef="name">
                <th mat-header-cell
                    *matHeaderCellDef> Título </th>
                <td mat-cell
                    *matCellDef="let row"> {{ row.booleanQuestionName || row.listQuestionName }} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell
                    *matHeaderCellDef> Tipo de verificação </th>
                <td mat-cell
                    *matCellDef="let row">
                    {{ 'PROPERTY.QUESTION_TYPE.' + QUESTION_TYPE[row.questionType] | translate }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let row; let index = index;"
                    class="d-flex justify-content-end">
                    <button mat-icon-button
                            type="button"
                            color="primary"
                            (click)="editQuestion(row)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button
                            type="button"
                            color="warn"
                            (click)="removeQuestion(index)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="['name', 'type', 'actions']"></tr>
            <tr mat-row
                *matRowDef="let row; columns: ['name','type','actions'];"></tr>
        </table>


    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid"
            [mat-dialog-close]="form.value">Salvar</button>
</mat-dialog-actions>