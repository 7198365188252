import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Utilities } from '../../../class/utilities';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../service/layout.service';
import { RoService } from '../../../service/ro.service';

@Component({
  selector: 'app-ro-schedule',
  templateUrl: './ro-schedule.component.html',
  styleUrls: ['./ro-schedule.component.css'],
})
export class RoScheduleComponent implements OnInit, OnDestroy {

  form: FormGroup;
  now = new Date();
  changed = false;

  private appointment: Date;
  private destroy = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { ro: string },
    public dialogRef: MatDialogRef<RoScheduleComponent>,
    private roService: RoService,
    private layout: LayoutService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    this.getData();
    interval(1000).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.now = new Date();
      this.form.get('appointment').updateValueAndValidity();
      this.form.get('time').updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.destroy.next(1);
    this.destroy.complete();
  }

  private async getData() {
    this.layout.loader = true;
    try {
      const ro = await this.roService.get(this.data.ro);
      if (ro.appointment) {
        this.appointment = new Date(ro.appointment);
        this.form.get('appointment').setValue(this.appointment);
        this.form.get('time').setValue(this.datePipe.transform(this.appointment, 'HH:mm'));
      }
    } catch (error) {
    } finally {
      this.layout.loader = false;
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      appointment: [this.now, [Validators.required]],
      time: [this.datePipe.transform(this.now, 'HH:mm'), [Validators.required, this.validateTime()]]
    });
    this.form.get('appointment').markAsTouched();
    this.form.get('appointment').markAsDirty();
    this.form.get('time').markAsTouched();
    this.form.get('time').markAsDirty();
  }

  private validateTime(): ValidatorFn {
    return control => {
      if (!this.form || control.value == '') {
        return null;
      }
      const date = Utilities.dateAndTime(new Date(this.form.value.appointment), control.value);
      if (date > this.now || date.getFullYear() != this.now.getFullYear()
        || date.getMonth() != this.now.getMonth() || date.getDate() != this.now.getDate()
        || (date.getHours() == this.now.getHours() && date.getMinutes() == this.now.getMinutes())
      ) {
        this.changed = !this.appointment || this.appointment.getTime() != date.getTime();
        return null;
      }
      return { past: true };
    };
  }

  async onSubmit() {
    const date = Utilities.dateAndTime(new Date(this.form.value.appointment), this.form.value.time);
    this.dialogRef.close(date);
  }

}
