import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TitleType } from '../../../interface/title-type';
import { TitleTypeService } from '../../../service/title-type.service';

@Component({
  selector: 'app-operation-without-titletype',
  templateUrl: './operation-without-titletype.component.html',
  styleUrls: ['./operation-without-titletype.component.css']
})
export class OperationWithoutTitletypeComponent implements OnInit {

  selectedTitle: string;
  titleTypes: TitleType[];

  constructor(
    private titleTypeService: TitleTypeService,
    private dialogRef: MatDialogRef<OperationWithoutTitletypeComponent>
  ) { }

  ngOnInit() {
    this.getExpenses()
  }

  private async getExpenses() {
    const types = await this.titleTypeService.getAll()
    this.titleTypes = types;
  }

  public async close(save: boolean) {
    if (save) {
      this.dialogRef.close(this.selectedTitle)
    } else {
      this.dialogRef.close()
    }
  }

}
