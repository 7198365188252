<h1 mat-dialog-title>
    A operação não possui um tipo de despesa
</h1>

<mat-dialog-content>
    <mat-card-subtitle>
        A operação vinculada à esse orçamento não possui um tipo de despesa.
        Selecione um tipo de despesa para que os títulos financeiros sejam criados.
    </mat-card-subtitle>

    <mat-form-field class="col-6 justify-content-start">
        <mat-label>Tipo de despesa</mat-label>
        <mat-select [(ngModel)]="selectedExpense">
            <mat-option *ngFor="let expense of expenseTypes"
                        [value]="expense._id">
                {{ expense.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button aria-label="cancel"
            mat-raised-button
            color="accent"
            type="button"
            (click)="close(false)">
        {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button type="button"
            aria-label="save"
            mat-raised-button
            color="primary"
            [disabled]="!selectedExpense"
            (click)="close(true)">
        {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
    </button>
</mat-dialog-actions>