<div [ngClass]="['firebase-card', 'mat-elevation-z8', (layout.isHandset$ | async) ? 'mobile' : 'desktop']">

  @if(!(layout.isHandset$ | async)){
    @switch (APP_NAME) {
      @case ("MekanicWEB") {
        <div class="robot-column">
          <div class="speech-bubble">{{ 'label_forgot_password_greeting' | translate }}</div>
          <img *ngIf="email.valid"
                class="info-image"
                src="/assets/images/robot/robot_default.svg" />
          <img *ngIf="email.invalid"
                class="info-image"
                src="/assets/images/robot/robot_creep.svg" />
        </div>
      }
      @case ("PO360") {
        <img src="assets/PO360/Logomarca/PNG/VERT. Escuro Principal.png"
              style="max-width: 50%;"
              alt="PO360">
      }
      @case ("Makeasy") {
        <img style="max-width: 50%;"
              src="/assets/images/makena_logos/MAKENA_360_bg-transparent.png"
              alt="Makeasy 360" />
      }
    }
  }
  
    <div>
      <h2>{{ 'ACTION.FIREBASE.RESET_PASSWORD' | translate }}</h2>
        <mat-form-field class="larger-field"
                        appearance="outline">
          <mat-label>{{ 'PROPERTY.PERSON.EMAIL' | translate }}</mat-label>
          <input matInput
                 required
                 type="email"
                 autocomplete="email"
                 [formControl]="email" />
          <mat-icon matPrefix>email</mat-icon>
          <mat-error *ngIf="email.hasError('required')">
            {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
          </mat-error>
          <mat-error *ngIf="email.hasError('isEmail')">
            {{ 'VALIDATION.INVALID_EMAIL' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="button-group">
          <button aria-label="back"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="goBack()">
            <mat-icon class="button-icon">arrow_back</mat-icon>
            {{ 'label_back_button' | translate }}
          </button>
          <button aria-label="reset"
                  mat-raised-button
                  color="accent"
                  type="button"
                  (singleClick)="onSubmit()"
                  [disabled]="email.invalid">
            <mat-icon class="button-icon">email</mat-icon>
            {{ 'label_password_resetlink_btn' | translate }}
          </button>
        </div>
    </div>
  </div>