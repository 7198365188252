import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private auth: AngularFireAuth,
    private router: Router
  ) { }


  canActivate(): Observable<boolean> {
    return this.auth.authState.pipe(
      map(authState => Boolean(authState)),
      map(auth => {
        if (!auth) {
          console.warn("Usuário não autenticado, componente não ativado")
          this.router.navigate(['/sign-in']);
        }
        return auth;
      })
    )
  }

  canLoad(): Observable<boolean> {
    return this.auth.authState.pipe(
      map(authState => Boolean(authState)),
      map(auth => {
        if (!auth) {
          console.warn("Usuário não autenticado, modulo não carregado")
          this.router.navigate(['/sign-in']);
        }
        return auth;
      })
    )
  }

}
