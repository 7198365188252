import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utilities } from '../../../class/utilities';
import { AddressService } from '../../../service/address.service';
import { DataService } from '../../../service/data.service';
import { Router } from '@angular/router';
import { ReverseRouterService } from '../../../service/reverse-router.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../service/layout.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent extends UnsubscribeAll implements OnInit, OnDestroy {

  Utilities = Utilities;

  constructor(
    public dataService: DataService,
    private addressService: AddressService,
    private layout: LayoutService,
    private router: Router,
    private reverseRouter: ReverseRouterService,
    private translate: TranslateService,
  ) {
    super();
    const localLanguage = Utilities.getLanguage()
    const selectedLanguage: string = localLanguage ? localLanguage : translate.getDefaultLang()
    this.setLanguage(selectedLanguage)
  }

  private setLanguage(language: string) {
    // Specifies the available languages to the service
    // this.translate.addLangs(['en', 'pt', 'es'])
    // Specifies the current languages to the service
    this.translate.use(language);
    // // this.dateAdapter.setLocale(Utilities.getLanguageAndCountry());
  }

  ngOnInit() {
    this.layout.component = 'user';
    const sub = this.dataService.user$.subscribe(() => {
      this.getData();
      this.reverseRouter.clear();
    });
    this.subs.push(sub);
  }

  override ngOnDestroy() {
    this.unsubscribeAll();
  }

  private async getData() {
    try {
      const states = await this.addressService.getStates()
      for (let state of states) {
        if (state.id == this.dataService.user.address.state) {
          this.dataService.user.address.stateName = state.name;
          for (let city of state.cities) {
            if (city.id == this.dataService.user.address.city) {
              this.dataService.user.address.cityName = city.name;
              break;
            }
          }
          break;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.layout.loader = false;
    }
  }

  async edit() {
    const from = {
      component: 'user-profile',
      address: [this.router.url],
      partialData: undefined
    }
    const to = {
      component: 'person-detail',
      address: ['/company', this.dataService.company.id, 'person', 'user', this.dataService.user.id],
      partialData: undefined
    }
    this.reverseRouter.navigate(from, to);
  }

}